.add-trans-btn{
    border: 0px;
    color: white;
    background-color: rgb(44, 167, 101);
    border-radius: 10px !important;
}

.add-trans-btn:hover{
    background-color: rgba(44, 167, 101, 0.903) !important;
}

.select__control{
    border-radius: 10px !important;
}