.settings-form-lable{
    color: #fff;
    text-shadow: 4px 2px 3px rgba(105, 104, 104, 0.525);
}
.settings-edit-btn{
    color: rgb(180, 34, 34);
    border: 0px;
    background-color: transparent;
}
.settings-edit-btn:hover{
    color: rgb(214, 40, 40);
}
.settings-edit-user-btn{
    border: 0px;
    color: white;
    background-color:rgb(180, 34, 34);
    border-radius: 10px;
}
.settings-edit-user-btn:hover{
    background-color: rgb(214, 40, 40);
}