.edit-profit{
    border: 0px;
    border-radius: 10px;
    color: #FF6F61;
}
.edit-profit:hover{
    color: #e44637;
}
.edit-icon{
    cursor: pointer;
}