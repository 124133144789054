 /* .dashboard{
     --bs-gutter-x: 0rem !important;
}  */

/* .sidenav---collapsed---LQDEv{
    width: 60px !important;
    min-height: 100vh;
}
.sidenav---expanded---1KdUL{
    width: 240px !important;
    min-height: 100vh;   
} */
.sidenav---sidenav---_2tBP{
    position: fixed !important;
    background-color: #2a1255 !important;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    /* min-height: 100vh !important;    */
}
.sidenav---sidenav-nav---3tvij{
    background-color: #2a1255 !important;   

}

.Admin{
    background-color: #2a1255 ;
}
.Admin-body{
    margin-left: 60px !important;
    min-height: 100vh;   
    background-color: rgba(239, 239, 239, 0.999) !important;   
}
@media only screen and (max-height: 900px) {
    .sidenav---sidenav---_2tBP{
        overflow-y: scroll !important;
    }
}