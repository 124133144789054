.transactions{
    background-color: rgba(1, 251, 147, 0.375);
}
.manual-transactions{
    background-color: #fffa65f1;
}
.add_trans{
    background-color: rgb(44, 167, 101);
    border: 0px;
    border-radius: 50px;
    color: white;
}
.add_trans:hover{
    background-color: rgb(36, 141, 85);
}
@media only screen and (max-width: 1164px) {
    .transaction-date{
        font-size: 17px;
        padding-top: 10px;
    } 
}

@media only screen and (max-width: 991px) {
    .transaction-date{
      font-size: 25px ;
    }
}

@media only screen and (max-width: 368px) {
    .FreeLancer-Details{
      font-size: 22px ;
    }
}