.sidenav---sidenav-toggle---1KRjR {
    height: 50px !important;
}

.log-out {
    background-color: #DC3545 !important;
}

.log-out:hover {
    background-color: #df192d !important;
    color: green !important;

}

.sidenav---sidenav-navitem---uwIJ- {
    color: white !important;
}

.sidebar {
    position: relative;
    left: -4px !important;
    height: 100vh !important;
}

.nav-link {
    margin-top: 10px !important;
}

.f4 {
    font-size: 24px !important;
}

.f5 {
    font-size: 20px !important;
}

 @media only screen and (max-height: 900px) {
    .nav-link {
        margin-top: 0px !important;
        padding: 0px !important;
        margin: 0px !important;
    }
} 