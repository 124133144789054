.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid  #DC3545;
  border-color:  #DC3545 transparent  #DC3545 transparent ;
  animation: lds-dual-ring 1.2s linear infinite;
}

.loading-spinner__overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-footer{
  border: 0px !important;
}
.modal-delete-btn{
  background-color: rgb(201, 26, 26) !important;
  border: 0px !important;
}
.modal-delete-btn:hover{
  background-color: rgb(237, 26, 26)   !important;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
